export const useClientUrl = () => {
  const {
    public: {
      backend: { uriClient },
    },
  } = useRuntimeConfig()

  const url = new URL(uriClient)

  const toUrl = (path: string) => `${url.origin}${path}`

  return { clientUrl: readonly(url), toUrl }
}
